<i18n>
ru:
  downloadAppUmbar: Наше приложение
  downloadAppWold: Скачать приложение
ua:
  downloadAppUmbar: Наш додаток
  downloadAppWold: Завантажити додаток
us:
  downloadAppUmbar: Our app
  downloadAppWold: Download the app
</i18n>

<template>
  <arora-button
    ignore-settings
    :class-name="`v-btn v-btn-header-qr-code v-btn-header-qr-code__${appConfig.VueSettingsPreRun.HeaderLayout} v-align-items-center`"
    :label="translate(`qrCodeButton.downloadApp${appConfig.VueSettingsPreRun.HeaderLayout}`)"
    @click="() => openPopup()"
  >
    <div class="v-btn-header-qr-code--icon">
      <icon-header-qr-code />
    </div>
    <span
      class="v-btn-header-qr-code--text"
      v-html="translate(`qrCodeButton.downloadApp${appConfig.VueSettingsPreRun.HeaderLayout}`)"
    />
  </arora-button>
</template>

<script setup lang="ts">
const { translate } = useI18nSanitized()
const popupStore = usePopupStore()
const appConfig = useAppConfig()

function openPopup(): void {
  popupStore.openPopup({
    popupClosable: true,
    popupName: 'qrAppPopup'
  })
}
</script>

<style lang="scss">
@use 'assets/variables';

.v-btn-header-qr-code {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &.v-btn {
    background: variables.$HeaderBackgroundColorContrast5;
    padding: 4px 12px 4px 4px;

    &:hover {
      background: variables.$HeaderBackgroundColorContrast10;
    }
  }

  &__Wold {
    .v-btn-header-qr-code--icon {
      border: 2px solid variables.$HeaderColor;
      color: variables.$HeaderColor;
    }
  }

  &__Umbar {
    .v-btn-header-qr-code--icon {
      border: 2px solid variables.$SecondaryBackgroundColor;
      color: variables.$SecondaryBackgroundColor;
    }
  }

  &--icon {
    border-radius: 50%;

    min-width: 32px;
    max-width: 32px;
    height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 4px;
  }

  &--text {
    font-size: variables.$TextSizeMain;
    text-wrap: nowrap;
    color: variables.$HeaderColor;
  }
}
</style>
